"use client";
import React from "react";
import { MdLocalPhone, MdOutlineFax, MdOutlineEmail } from "react-icons/md";
import { AiOutlineMobile } from "react-icons/ai";

const section =
  "w-full h-screen relative bg-sky-100 text-gray-700 flex flex-col justify-start items-center overflow-hidden pb-8";
const container =
  "w-3/4 mt-16 py-8 flex flex-col md:flex-row justify-around items-center border-b-2 border-gray-700";
const left =
  "w-full flex flex-row md:flex-col justify-start items-center md:items-baseline my-8";
const right =
  "w-full flex flex-col justify-start items-baseline my-4 text-right p-2";
const bottom =
  "w-3/4 flex flex-col md:flex-row justify-between items-center pb-64 p-4 text-center";
const divider = "w-full h-48 bg-sky-200";
const contactInfo = "w-full flex justify-start items-center text-right";

const Footer = () => {
  return (
    <section className={section}>
      <div className={divider} />

      <div className={container}>
        <div className={left}>
            <img src="./meyuLogo.png" className="w-16 m-4" alt="logo" />

          {/* <img src={logoImg} alt="Logo" className={logo} /> */}
          <p className="w-1/2 border-l-2 border-gray-400 px-2 mx-2 text-sm md:text-lg">
            メーユーサービス有限会社
          </p>
          <p className="w-1/2 border-l-2 border-gray-400 px-2 mx-2 text-sm md:text-lg">
            Meyu Service Co. LTD
          </p>
        </div>
        <div className={right}>
          <div className="w-full text-sectionTitle3 text-left py-2">
            Contact
          </div>
          <div className={contactInfo}>
            <MdLocalPhone size={20} /> &nbsp;+81-3-6809-3199{" "}
          </div>
          <div className={contactInfo}>
            <MdOutlineFax size={20} /> &nbsp;+81-3-6809-3288{" "}
          </div>
          <div className={contactInfo}>
            <AiOutlineMobile size={20} /> &nbsp;+81-90-6617-9899{" "}
          </div>
          <div className={contactInfo}>
            <MdOutlineEmail size={20} /> &nbsp;xiao2-k0115@mvc.biglobe.ne.jp{" "}
          </div>
        </div>
      </div>

      <div className={bottom}>
        <span>
          &copy; {new Date().getFullYear()} Meyu Service Co, LTD. All rights
          reserved.
        </span>
        {/* <span>
          Made with &#10084; by{" "}
          <a href="" target="_blank" rel="noopener">
            PINKDIAMONDVVS
          </a>
        </span> */}
      </div>
    </section>
  );
};

export default Footer;
