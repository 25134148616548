import React from "react";

const overlay =
  "absolute w-full h-full bg-black bg-opacity-60 top-0 left-0 z-10";
const overlay2 =
  "absolute w-full h-full bg-cyan-900 bg-opacity-60 top-0 left-0 z-5";

const Landing = () => {
  return (
    <div id="home" className="relative w-full flex justify-center ">
      <div className={overlay} />
      {/* <div className={overlay2} /> */}
      <img
        src="./images/landing1.webp"
        className="w-full h-screen-20 md:min-h-screen object-cover"
        alt="landing"
      />
      <div className="absolute top-40 md:top-1/4 w-4/5 md:w-3/5 text-md text-white md:text-sectionTitle3 text-left font-light z-20 md:pt-8 md:px-12 p-8 md:pb-4 rounded-xl bg-sky-900 bg-opacity-90 shadow-md backdrop-blur">
        At Meyu Service Co., LTD , we have more than 30 years of experience in
        the manufacturing of steel wires. We are nestled in Tokyo and Shanghai,
        our wire factory is serving markets not only in Japan and China but also
        extending to South Korea, the United States, and beyond. <br /> <br />
        Our wire company has an annual production capacity of 15,000 tons and we
        have confident to manufacture more and more. <br /> <br />
        At present, the main products are: carbon spring steel wire, phosphating
        steel wire, galvanized steel wire, smooth steel wire rope, galvanized
        steel wire rope, stainless steel wire, etc.
        <div className="w-full flex md:justify-end justify-center items-center pt-8">
          <img src="./meyuLogo.png" className="w-10 md:w-12 my-4 mr-4 md:ml-4" alt="logo" />
          <p className="w-36 border-l-2 border-gray-400 px-2 text-sm md:text-md">
          メーユーサービス有限会社
        </p>
        <p className="w-36 border-l-2 border-gray-400 px-2 text-sm md:text-md">
          Meyu Service Co. LTD
        </p>
        </div>
      </div>
    </div>
  );
};

export default Landing;
