import React from "react";
import { TbCircleNumber1,  TbCircleNumber2,  TbCircleNumber3} from "react-icons/tb";
import { PiNumberCircleOneLight } from "react-icons/pi";
import { PiNumberCircleTwoLight } from "react-icons/pi";
import { PiNumberCircleThreeLight } from "react-icons/pi";


const productContainer =
  "w-full h-min flex flex-col justify-center items-center p-8  ";
const productLeft = "w-full h-96 overflow-hidden flex rounded-xl shadow-sm";
const productRight =
  "w-full flex flex-col justify-center items-center p-4 md:m-8 ";

const productContainer2 =
  "w-full h-min flex flex-col md:flex-row justify-start items-center p-8  md:px-0";
const productLeft2 =
  "w-full md:w-128 h-min flex flex-col justify-start items-center  mx-8";
const productRight2 =
  "w-full flex flex-col justify-start items-baseline p-4 ";
const productTitle2 =
  "w-full flex justify-start items-center text-sectionTitle6 md:text-sectionTitle5 text-left md:px-4";
const productInfoItemDescription2 =
  "text-md md:text-lg pt-4 pb-8 md:px-4 text-left font-light";

const productInfo =
  "w-full flex flex-col justify-start items-start text-center p-8 md:px-0 md:py-8";

const productTitle =
  "w-full text-sectionTitle3 md:text-sectionTitle5 text-center";
const productInfoItem =
  "w-full py-2 flex flex-col md:flex-row justify-between items-center";
const productInfoItemTitle =
  "flex jusitfy-start items-center text-md md:text-xl font-bold";
const productInfoItemContent = "text-lg text-left md:text-right font-light";


const subTitle = "w-full text-sectionTitle4 md:text-sectionTitle2 underline text-gray-700 text-left px-8";

const Product = () => {
  return (
    <div
      className="w-full h-min flex flex-col justify-start items-center py-8 px-0 md:px-8"
      id="product"
    >
      <h1 className="w-full my-2 text-sectionTitle2 md:text-sectionTitle1 py-8 text-center">
        Our Product
      </h1>
      <div className={subTitle}>Wire</div>
      <div className="w-full flex flex-col md:flex-row">
        {/* Product 1  */}
        <div className={productContainer}>
          <div className={productLeft}>
            {/* <img
              src="./images/carbonSpring1.jpg"
              className="w-full object-cover"
              alt="product"
            /> */}
            <img
              src="./images/carbonSpring2.jpg"
              className="w-full object-cover"
              alt="product"
            />
          </div>

          <div className={productRight}>
            <div className={productTitle}>Carbon Spring Steel Wire</div>
            <ul className={productInfo}>
              {/* raw material */}
              <li className={productInfoItem}>
                <div className={productInfoItemTitle}>Raw Material:</div>
                <div className={productInfoItemContent}>
                  45#,62A,72A,80#,82A,92A
                </div>
              </li>
              {/* wire diameter */}
              <li className={productInfoItem}>
                <div className={productInfoItemTitle}>Wire Diameter:</div>
                <div className={productInfoItemContent}>0.15mm &amp; above</div>
              </li>
              {/* tensile strength */}
              <li className={productInfoItem}>
                <div className={productInfoItemTitle}>
                  Tensile Strength(N/mm²):
                </div>
                <div className={productInfoItemContent}>1372--3090</div>
              </li>
            </ul>
          </div>
        </div>

        {/* Product 2  */}
        <div className={productContainer}>
          <div className={productLeft}>
            <img
              src="./images/heatTreatedPhosphated1.jpg"
              className="w-full object-cover"
              alt="product"
            />
          </div>

          <div className={productRight}>
            <div className={productTitle}>
              Heat Treated Phosphated Steel Wire
            </div>
            <ul className={productInfo}>
              {/* raw material */}
              <li className={productInfoItem}>
                <div className={productInfoItemTitle}>Raw Material:</div>
                <div className={productInfoItemContent}>
                  45#,62A,72A,80#,82A,92A
                </div>
              </li>
              {/* wire diameter */}
              <li className={productInfoItem}>
                <div className={productInfoItemTitle}>Wire Diameter:</div>
                <div className={productInfoItemContent}>1.1mm---4.0mm</div>
              </li>
              {/* tensile strength */}
              <li className={productInfoItem}>
                <div className={productInfoItemTitle}>
                  Tensile Strength(N/mm²):
                </div>
                <div className={productInfoItemContent}>880--1450</div>
              </li>
            </ul>
          </div>
        </div>

        {/* Product 3  */}
        <div className={productContainer}>
          <div className={productLeft}>
            <img
              src="./images/galvanized1.jpg"
              className="w-full object-cover"
              alt="product"
            />
          </div>

          <div className={productRight}>
            <div className={productTitle}>Galvanized Steel Wire</div>
            <ul className={productInfo}>
              {/* raw material */}
              <li className={productInfoItem}>
                <div className={productInfoItemTitle}>Raw Material:</div>
                <div className={productInfoItemContent}>
                  45#,62A,72A,80#,82A,92A
                </div>
              </li>
              {/* wire diameter */}
              <li className={productInfoItem}>
                <div className={productInfoItemTitle}>Wire Diameter:</div>
                <div className={productInfoItemContent}>0.30mm---1.4mm</div>
              </li>
              {/* tensile strength */}
              <li className={productInfoItem}>
                <div className={productInfoItemTitle}>
                  Tensile Strength(N/mm²):
                </div>
                <div className={productInfoItemContent}>1372--3090</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={subTitle}>Rope</div>
      {/* Product 4  */}
      <div className={productContainer2}>
        <div className={productLeft2}>
          <img
            src="./images/rope1.jpg"
            className="w-full object-cover rounded-xl py-2"
            alt="product"
          />
          <img
            src="./images/rope2.jpg"
            className="w-full object-cover rounded-xl py-2"
            alt="product"
          />
        </div>

        <div className={productRight2}>
          <div className={productTitle2}>
            <PiNumberCircleOneLight size={36} className="mr-2"/> 
            Smooth Steel Wire Rope
            </div>
          <div className={productInfoItemDescription2}>
            Multi- strand rope,,,6*7, 6*24,18 * 7, 35 W * 7 and other Production
            does not rotate wire rope to adapt to lifting, lifting and other
            special equipment.
          </div>

          <div className={productTitle2}>
          <PiNumberCircleTwoLight size={36} className="mr-2"/> 
            Forged Steel Wire Rope
            </div>
          <div className={productInfoItemDescription2}>
            The forged steel wire ropes produced by our factory are mainly used
            for mining lifting, forestry lifting, and other fields, and their
            service life is much longer than that of ordinary smooth ropes
          </div>

          <div className={productTitle2}>
          <PiNumberCircleThreeLight size={36} className="mr-2"/> 
            Galvanized Steel Wire Rope
            </div>
          <div className={productInfoItemDescription2}>
            Our factory produces galvanized steel wire ropes for point contact
            and line contact, as well as zinc aluminum alloy coated steel wire
            ropes, suitable for fields such as shipbuilding, aviation, and
            lifting
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
