import React from 'react'
import ContactForm from './ContactForm'

const Contact = () => {
  return (
    <div id="contact" className="relative w-full h-min flex flex-col md:flex-row justify-center items-center bg-sky-800 py-12 md:py-32">
        <img src="./images/contact2.webp" alt="contact" className="w-full md:w-128 md:h-128 object-cover object-center rounded-full p-8 md:m-8 opacity-90" />
        <ContactForm/>
        {/* <ContactRestAPI/> */}
    </div>
  )
}

export default Contact