
import "./App.css";
import Landing from "./components/Landing";
import Navbar from "./components/Navbar";
import { useRef} from "react";
import Product from "./components/Product";
import Footer from "./components/Footer";
import Contact from "./components/Contact";

function App() {
  const containerRef = useRef(null);

  return (
        <div className="w-full h-min" data-scroll-container ref={containerRef}>
          <Navbar />
          <Landing />
          <Product />
          <Contact />
          <Footer />
        </div>
  );
}

export default App;
